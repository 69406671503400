/* @tailwind base;
@tailwind components;
@tailwind utilities; */

:root {
  --margintop: 77px;
  --paddingTop: 77px;
  /* var(paddingtop) */
}

.paddingtop {
  padding-top: var(--paddingTop) !important;
  /* border: 1px solid red; */
}

.paddingbottom {
  padding-bottom: var(--paddingTop) !important;
}

.paddingtopbottom {
  padding-top: var(--paddingTop) !important;
  padding-bottom: var(--paddingTop) !important;
  /* border: 1px solid red; */
}

.zerobottom {
  padding-bottom: 0px !important;
  /* border: 1px solid red; */
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  text-decoration: none !important;
}

.relative {
  position: relative;
}

.react-tabs__tab-list {
  border-bottom: none !important;
}

.bg-none {
  /* background-color: #f5f5f5 !important; */
  background-image: none;
}

/* Start Active tab of PackageTab Component */
.nav-item {
  position: relative;
}

.activeTab {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  /* Adjust opacity as needed */
}

.activeTab-content {
  color: #f3f4f6;
  text-align: center;
  position: relative;
  z-index: 1;
}

/* End Active tab of PackageTab Component */

.icon-style {
  width: 30px;
  height: 30px;
  font-weight: bold;
}


.included-html li::before {
  /* content: '\e123'; This Unicode corresponds to the bi-check icon */
  /* content: 'A';  */
  /* content: '<i className="bi bi-check" />';  */
  content: '';
  font-family: 'BootstrapIcons';
  margin-right: 8px;
}

.excluded-html {}

.points-html {}


.modalContainer {
  position: absolute !important;
  width: 100vw;
  height: 100%;
  background: #000;
  z-index: 9998;
}

.modalForm {
  position: absolute !important;
  left: 50% !important;
  right: 50vw !important;
  width: fit-content;
   /* top: 50%; */
   /* left: 50%; */
  transform: "translate(-50%, -50%)";
  z-index: 9999;
}

.form-check-input:checked {
    background-color: #63ab45;
    border-color: #63ab45;
}

.textDecoration-none {
  text-decoration: dashed !important;
  /* border: 2px solid purple; */
  border: none !important;
}

.teams-card> img {
  /* border: 1px solid red; */
  max-height: 450px;
}

.teams-card  {
  max-height: 500px;
}

.hidden-content {
  display: none; /* Completely removes the element */
  opacity: 0;
  transition: opacity 0.5s ease;
}

.hover-area:hover .hidden-content {
  display: block; /* Makes it appear */
  opacity: 1;
}