.navphone {
    max-width: 170px;
}

.plus-animation::after {
    content: "+";
    display: inline-block;
    transition: content 1000ms;
}

.drop-down:hover .plus-animation::after {
    content: "-";
    transform: rotate(0);
}

/* .plus-animation {
    transition: transform 0.5s;
    display: inline-block;
}

.drop-down:hover .plus-animation::after {
    content: "-";
    transform: rotate(0);
} */

/* .plus-animation:hover {
    content: "";
    transform: rotate(0);
} */

.menu-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
    width: 100%;
}

.menu-list li {
    margin: 0;
    padding: 0;
}

.menu-list a {
    display: block;
    padding: 12px;
    text-decoration: none;
    color: #333;
    font-size: 16px;
    border-bottom: 1px solid #ddd;
}

.menu-list a:hover {
    /* background-color: #f0f0f0; */
    font-weight: bold;
}

.sub-menu {
    list-style-type: none;
    margin: 0;
    padding: 0;
    padding-left: 20px;
    /* Indent for sub-menu */
}

.sub-menu li {
    margin: 0;
    padding: 0;
}

.plus-animation::after {
    content: ' +';
    /* Add plus sign */
    font-weight: bold;
    float: right;
}

.drop-down {
    position: relative;
}

.drop-down:hover .sub-menu {
    display: block;
}

.nav-flex {
    display: flex;
    justify-content: space-between;
    
}

.broderred{
    /* border: 1px solid red; */
    
}

.menu-new {

    margin: 0;
    /* display: flex;
    justify-content: center; */
    width: fit-content;
}

.nav-right {
    margin-left: 0px;
    /* border: 1px solid blueviolet */
}

@media (max-width: 768px) {
    .menu-list a {
        font-size: 10px;
        padding: 0px;
    }

    .plus-animation::after {
        font-size: 14px;
    }
}