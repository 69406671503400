.why-height {
    height: 270px !important;
}

.whyicon {
    font-size: 70px;
    color: #63ab45;
    transition: color 0.5s ease-in;
}

.whyicon2 {
    font-size: 70px;
    color: #fbb03b;
    transition: color 0.5s ease-in;
}

.why-height:hover .whyicon {    
    color: white;
}

.why-height:hover .whyicon2 {    
    color: white;
}